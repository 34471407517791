import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import { RippleModule } from 'primeng/ripple';
import { ButtonModule } from 'primeng/button';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { UserImageBlockComponent } from '../user-image-block/user-image-block.component';
import { Role } from '../../interfaces/global.enums';
import { AuthService } from '../../models/auth/auth.service';
import { DatePipe } from '@angular/common';
import { AccountMenuLinkItem, OrderMenuLinkItem } from './interfaces';


@Component({
  selector: 'app-user-inner-menu',
  standalone: true,
  imports: [RippleModule, ButtonModule, RouterModule, UserImageBlockComponent, DatePipe],
  templateUrl: './user-inner-menu.component.html',
  styleUrl: './user-inner-menu.component.scss'
})
export class UserInnerMenuComponent implements OnInit {
  @Input() stickyToTop!: boolean; 
  @Input() inMobileSidenav!: boolean; 
  @Input() profileMenuList: OrderMenuLinkItem[] | AccountMenuLinkItem[] = [];
  @Input() user: any;
  
  @Output() closeUserSidenavEvent: EventEmitter<any> = new EventEmitter<any>(); 

  public Role = Role;



  constructor(public router: Router, public authService: AuthService){

  }


  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateActiveStatus();
      }
    });
    
    this.updateActiveStatus();
  }

  public triggerEventsAfterLinkClick(){
    if(this.inMobileSidenav){
        this.closeUserSidenavEvent.emit();
    }
  }

  
  updateActiveStatus(): void {
    const currentUrl = this.router.url;

    this.profileMenuList.forEach(item => {

        item.active = currentUrl.includes(item.routerLink);
        if(item.children){
          item.children.forEach(child => {
            child.active = currentUrl.includes(child.routerLink);
            if(child.active){
              item.active = true;
            }
          })
        }
    });
  }

}
